import sets from './data/sets.json';
import setItems from './data/setItems.json';
import setItemsInfo from './data/setitemsInfo.json';
import dungeons from './data/dungeons.json';
import events from './data/events.json';
import legendaryGems from './data/legendaryGems.json';
import gems from './data/gems.json';

export const BUILD_VERSION = '2.3.0';
export const IMAGES_VERSION = '2.2.2';
export const LAST_UPDATED = '01/10/2024';
export const IMAGE_PATH = '/static/images';

export const SETS = sets;
export const SET_ITEMS = setItems;
export const SET_ITEMS_INFO = setItemsInfo;
export const DUNGEONS = dungeons;
export const LEGENDARY_GEMS = legendaryGems;
export const GEMS = gems;
export const EVENTS = events;

// Legendary gems upgrade tables

export const UPDATE_TABLE_1STAR = [
    { rank: 2, gemPower: 1, gemRank1: 0, gemRank3: 0, gemRank5: 0, upgradeGemPower: 1, upgradeRank1Gems: 0, totalGemPower: 1, totalRank1Gems: 0 },
    { rank: 3, gemPower: 5, gemRank1: 0, gemRank3: 0, gemRank5: 0, upgradeGemPower: 5, upgradeRank1Gems: 0, totalGemPower: 6, totalRank1Gems: 0 },
    { rank: 4, gemPower: 10, gemRank1: 0, gemRank3: 0, gemRank5: 0, upgradeGemPower: 10, upgradeRank1Gems: 0, totalGemPower: 16, totalRank1Gems: 0 },
    { rank: 5, gemPower: 15, gemRank1: 0, gemRank3: 0, gemRank5: 0, upgradeGemPower: 15, upgradeRank1Gems: 0, totalGemPower: 31, totalRank1Gems: 0 },
    { rank: 6, gemPower: 20, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 20, upgradeRank1Gems: 1, totalGemPower: 51, totalRank1Gems: 1 },
    { rank: 7, gemPower: 25, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 25, upgradeRank1Gems: 1, totalGemPower: 76, totalRank1Gems: 2 },
    { rank: 8, gemPower: 30, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 30, upgradeRank1Gems: 1, totalGemPower: 106, totalRank1Gems: 3 },
    { rank: 9, gemPower: 40, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 40, upgradeRank1Gems: 1, totalGemPower: 146, totalRank1Gems: 4 },
    { rank: 10, gemPower: 50, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 50, upgradeRank1Gems: 1, totalGemPower: 196, totalRank1Gems: 5 },
]

export const UPDATE_TABLE_2STARS = [
    { rank: 2, gemPower: 5, gemRank1: 0, gemRank3: 0, gemRank5: 0, upgradeGemPower: 5, upgradeRank1Gems: 0, totalGemPower: 5, totalRank1Gems: 0 },
    { rank: 3, gemPower: 15, gemRank1: 0, gemRank3: 0, gemRank5: 0, upgradeGemPower: 15, upgradeRank1Gems: 0, totalGemPower: 20, totalRank1Gems: 0 },
    { rank: 4, gemPower: 25, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 25, upgradeRank1Gems: 1, totalGemPower: 45, totalRank1Gems: 1 },
    { rank: 5, gemPower: 0, gemRank1: 1, gemRank3: 1, gemRank5: 0, upgradeGemPower: 20, upgradeRank1Gems: 2, totalGemPower: 65, totalRank1Gems: 2 },
    { rank: 6, gemPower: 0, gemRank1: 0, gemRank3: 1, gemRank5: 1, upgradeGemPower: 85, upgradeRank1Gems: 5, totalGemPower: 150, totalRank1Gems: 8 },
    { rank: 7, gemPower: 0, gemRank1: 0, gemRank3: 1, gemRank5: 1, upgradeGemPower: 85, upgradeRank1Gems: 5, totalGemPower: 235, totalRank1Gems: 13 },
    { rank: 8, gemPower: 0, gemRank1: 0, gemRank3: 2, gemRank5: 1, upgradeGemPower: 105, upgradeRank1Gems: 6, totalGemPower: 340, totalRank1Gems: 19 },
    { rank: 9, gemPower: 0, gemRank1: 0, gemRank3: 1, gemRank5: 2, upgradeGemPower: 150, upgradeRank1Gems: 9, totalGemPower: 490, totalRank1Gems: 28 },
    { rank: 10, gemPower: 0, gemRank1: 0, gemRank3: 0, gemRank5: 3, upgradeGemPower: 195, upgradeRank1Gems: 12, totalGemPower: 685, totalRank1Gems: 40 },
]

export const UPDATE_TABLE_5STARS = [
    { rank: 2, gemPower: 50, gemRank1: 0, gemRank3: 0, gemRank5: 0, upgradeGemPower: 50, upgradeRank1Gems: 0, totalGemPower: 50, totalRank1Gems: 0 },
    { rank: 3, gemPower: 75, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 75, upgradeRank1Gems: 1, totalGemPower: 125, totalRank1Gems: 1 },
    { rank: 4, gemPower: 100, gemRank1: 1, gemRank3: 0, gemRank5: 0, upgradeGemPower: 100, upgradeRank1Gems: 1, totalGemPower: 225, totalRank1Gems: 2 },
    { rank: 5, gemPower: 0, gemRank1: 1, gemRank3: 2, gemRank5: 0, upgradeGemPower: 250, upgradeRank1Gems: 5, totalGemPower: 475, totalRank1Gems: 7 },
    { rank: 6, gemPower: 0, gemRank1: 0, gemRank3: 3, gemRank5: 0, upgradeGemPower: 375, upgradeRank1Gems: 6, totalGemPower: 850, totalRank1Gems: 13 },
    { rank: 7, gemPower: 0, gemRank1: 0, gemRank3: 2, gemRank5: 1, upgradeGemPower: 725, upgradeRank1Gems: 12, totalGemPower: 1575, totalRank1Gems: 25 },
    { rank: 8, gemPower: 0, gemRank1: 0, gemRank3: 2, gemRank5: 1, upgradeGemPower: 725, upgradeRank1Gems: 12, totalGemPower: 2300, totalRank1Gems: 37 },
    { rank: 9, gemPower: 0, gemRank1: 0, gemRank3: 1, gemRank5: 2, upgradeGemPower: 1075, upgradeRank1Gems: 18, totalGemPower: 3375, totalRank1Gems: 55 },
    { rank: 10, gemPower: 0, gemRank1: 0, gemRank3: 1, gemRank5: 2, upgradeGemPower: 1075, upgradeRank1Gems: 18, totalGemPower: 4450, totalRank1Gems: 73 },
]

// Item types
export const ITEM_HANDS = 1;
export const ITEM_FEET = 2;
export const ITEM_WAIST = 3;
export const ITEM_NECK = 4;
export const ITEM_RING = 5;

// Legendary gem types
export const GEM_1STAR = 1;
export const GEM_2STARS = 2;
export const GEM_5STARS = 3;

// Actions
export const SET_ITEM_ADD = "SET_ITEM_ADD";
export const SET_ITEM_REMOVE = "SET_ITEM_REMOVE";
export const CLEAR_SELECTED_ITEMS = "CLEAR_SELECTED_ITEMS";

export const LEGENDARY_GEM_SELECT = "LEGENDARY_GEM_SELECT";
export const LEGENDARY_GEM_SEARCH = "LEGENDARY_GEM_SEARCH";
export const CLEAR_SELECTED_LEGENDARY_GEM = "CLEAR_SELECTED_LEGENDARY_GEM";
export const SET_AWAKENING = "SET_AWAKENING";
export const CLEAR_AWAKENING = "CLEAR_AWAKENING";

export const GEM_SELECT = "LEGENDARY_GEM_SELECT";
export const CLEAR_SELECTED_GEM = "CLEAR_SELECTED_GEM";

export const DUNGEON_SELECT = "DUNGEON_SELECT";
export const DUNGEON_DESELECT = "DUNGEON_DESELECT";
export const CLEAR_SELECTED_DUNGEONS = "CLEAR_SELECTED_DUNGEONS";
